.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.grid-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.logo {
  overflow: hidden;
  width:400px;
  transition: opacity .3s;
  margin-right: 4px;
}

@media (max-width: 899px) {
  .logo {
    max-width:180px;
  }
}

.logo img {
  object-fit: cover;
  width: 105%;
  height: auto;
}

.logo:hover {
  overflow: hidden;
  opacity: .6;
  transition: opacity .3s;
  cursor: pointer;
}

.important-points {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
}

.important-points > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.important-points > div.info > div:nth-child(1) {
  font-size: 2.2em;
  margin-right: 10px;
}

.important-points > div.info > div:nth-child(2) {
  font-weight: 100;
}